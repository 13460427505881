const serviceData = [
    {
        title: 'Wired & Wireless Infrastructure',
        description: 'We specialize in designing and deploying both wired and wireless network infrastructures that form the backbone of modern communications. Whether it’s a corporate office, a large campus, or an industrial facility, our network solutions ensure seamless connectivity, robust performance, and scalability for future needs.',
        image: '/images/wired_wireless_img.svg', // Replace with actual image path
        solutions: [
            'Router',
            'Fiber switches (Core & Distribution switches)',
            'Access Switches',
            'Firewall',
            'Load balancer',
            'Access Points',
            'Audio/Video Products &  Solutions'
        ],
    },
    {
        title: 'IoT Solutions',
        description: 'We understand that the future of building management and automation lies in the Internet of Things (IoT). By seamlessly connecting devices, sensors, and systems through IoT technologies, we help businesses achieve smarter operations, better data insights, and increased efficiency.',
        image: '/images/iot_solutions_img.svg', // Replace with actual image path
        solutions: [
            'Water supply monitoring system',
            'Real-time assets monitoring system',
            'Public response security system',
            'Server room monitoring system'
        ],
    },
    {
        title: 'AI Solutions',
        description: 'We offer cutting-edge AI Solutions designed to streamline your operations, enhance decision-making, and drive innovation. Whether it’s automating routine tasks, optimizing processes, or extracting valuable insights from data, our AI services are tailored to meet the specific needs of your industry.',
        image: '/images/ai_solutions_img.svg', // Replace with actual image path
        solutions: [
            'Security Information and Event Management (SIEM)',
            'Cloud Threat Exposure Management (CTEM)',
            'Endpoints protection',
            'End-to-end visibility of security & compliance',
            'Proactive detection & response',
            'AI-based threat detection & protection'
        ],
    },
    {
        title: 'SD-WAN Solutions',
        description: 'We deliver cutting-edge SD-WAN solutions designed to transform how businesses manage and optimize their wide area networks. Our SD-WAN services provide centralized control, enhanced security, and seamless connectivity across multiple locations, empowering your organization to embrace cloud technology, remote work, and high-performance networking.',
        image: '/images/sd_wan_img.svg', // Replace with actual image path
        solutions: [
            'Centralized control',
            'Enhanced security',
            'Seamless connectivity',
            'High-performance networking'
        ],
    },
    {
        title: 'Cyber Security Solutions',
        description: 'In today’s digital landscape, the security of your business’s data and systems is more critical than ever. We offer comprehensive Cybersecurity Solutions designed to protect your infrastructure from evolving threats, ensuring that your data, assets, and operations are secure.',
        image: '/images/cyber_security_img.svg', // Replace with actual image path
        solutions: [
            'Data protection',
            'Threat detection & response',
            'Identity-based breach prevention',
            'Administrator & privilege account monitoring'
        ],
    },
    {
        title: 'Facility Management Services',
        description: 'We specialize in providing comprehensive IT and ELV (Extra-Low Voltage) Facility Management Services. Our goal is to ensure that your technology infrastructure—spanning IT systems, low-voltage networks, and building technology—operates smoothly and efficiently.',
        image: '/images/facility_management_img.svg', // Replace with actual image path
        solutions: [
            'AMC for Multi Brand IT Systems',
            'Data Center Management Services',
            'Resident Engineers Staffing Services'
        ],
    }
];

export default serviceData;
