import React from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/header.css';

const Header = () => (
  <header className="bg-white text-black p-1 d-flex justify-content-between align-items-center">
    <NavLink to="/">
      <img src="/images/drift_logo.png" alt="Drift Tech Solution Logo" style={{ height: '100px' }} />
    </NavLink>
    <nav className="mx-auto text-black nav-links">
      <NavLink to="/" className="header-link" activeClassName="active">
        Home
      </NavLink>
      <NavLink to="/about-us" className="header-link" activeClassName="active">
        About Us
      </NavLink>
      <NavLink to="/career" className="header-link" activeClassName="active">
        Career
      </NavLink>
    </nav>
  </header>
);

export default Header;
