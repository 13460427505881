import React from "react";
import ServiceCard from "./ServiceCard";
import serviceData from "../data/serviceData";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const ServicesSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <section className="text-center" style={{ padding: isMobile? '5px 1.8rem': '3rem'}}>
      {/* Wrapper div with Linear Gradient Background */}
      <div
        style={{
          background:
            "linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(0, 130, 254, 0.08) 100%)",
          padding: isMobile ? "15px": "40px",
          paddingTop: isMobile ? '2px': '40px',
          borderRadius: "10px",
        }}
      >
        {/* Heading with Custom Typography */}
        <h4
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontSize: isMobile ? "32px": "64px",
            fontWeight: 700,
            lineHeight: "83.2px",
            textAlign: "center",
            color: "#ABBA7C",
          }}
          className=" mb-4"
        >
          Our Services
        </h4>

        {/* Short Content with New Typography */}
        <p
          style={{
            fontFamily: "Open Sans, sans-serif",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "32.68px",
            textAlign: "left",
          }}
        >
          We specialize in designing, installing, and maintaining advanced IT
          systems, tailored to meet the unique needs of businesses and
          properties. With a focus on smart, energy-efficient solutions, we
          ensure your systems are optimized for safety, reliability, and
          scalability.
        </p>
      </div>

      {/* Service Cards */}
      <div>
        {serviceData.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            image={service.image}
            solutions={service.solutions}
            reverse={index % 2 !== 0} // Reverse the image on odd-indexed cards
          />
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
