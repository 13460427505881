import React from 'react';

const Career = () => (
  <>
   <section className="container my-5">
    <div className="row align-items-center">
      {/* Left Column: Content */}
      <div className="col-md-6">
        <h1 style={{ fontSize: '48px', fontWeight: '700',color: "#ABBA7C" }}>Career</h1>
        
        <p style={{ fontSize: '18px', lineHeight: '32px' }}>
        We’re always looking for talented, driven professionals to join our team. As a leading provider of low-voltage systems, network infrastructure, and IT solutions, we pride ourselves on delivering cutting-edge technology and services that transform businesses. If you’re passionate about technology, problem-solving, and helping businesses grow, we want to hear from you.        </p>

      </div>

      {/* Right Column: Image */}
      <div className="col-md-6 position-relative">
        <img
          src="/images/pattern_design.svg"  // Replace with the path to your image
          alt="About Drift Tech Solution"
          className="img-fluid rounded pattern-img"
        />
      </div>
    </div>

    {/* Additional Image Below Content */}
    <div className="text-center mt-5">
      <img
        src="/images/career_img.svg"  // Replace with the actual path to your additional image
        alt="Additional Visual"
        className="img-fluid rounded"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
    </section>
  </>
);

export default Career;
