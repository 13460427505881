import React from 'react';
import { useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

const AboutSection = () => 
  {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
   
 return (
  <section className="container text-center p-5">
    <div className="row align-items-center">
      {/* Left Column: Content */}
      <div className="col-md-6">
        <h2
          className="mb-4"
          style={{
            fontFamily: 'Open Sans, sans-serif',
            fontSize: isMobile? '2.4rem': '3.438rem', // Title typography as before
            fontWeight: 700,
            lineHeight: isMobile? '3.5rem': '5.85rem',
            textAlign: 'left',
            color: '#E07547',
          }}
        >
      <b>Drift Tech Solutions</b>  <br></br>   <p style={{"color":"#ABBA7C"}}> - System Integration Company.</p>   
        </h2>
        <p
          style={{
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '18px', // Content typography
            fontWeight: 400,
            lineHeight: '32.68px',
            textAlign: 'left',
          }}
        >
          Welcome to Drift Tech Solution, where innovation meets reliability in IT infrastructure. Founded by a team of passionate technologists having rich experience of Multiple IT leading OEMs, we are dedicated to providing cutting-edge solutions that empower businesses to thrive in a digital-first world.
        </p>
      </div>

      {/* Right Column: Image */}
      <div className="col-md-6">
        <img src="/images/drift_tech_data_center.svg" alt="About Drift Tech Solution" className="img-fluid" />
      </div>
    </div>
  </section>
);
  }


export default AboutSection;
