import React from "react";
import AboutSection from "./components/AboutSection";
import ServicesSection from "./components/ServicesSection";
import PartnerSection from "./components/PartnerSection";
import ContactSection from "./components/ContactSection";

const Home = () => (
  <>
    
    <AboutSection />
    <ServicesSection />
    {/* <ContactSection /> */}
    <PartnerSection />
    
  </>
);

export default Home;
