import React from 'react';

const AboutUs = () => (
  <section className="container my-5">
    <div className="row align-items-center">
      {/* Left Column: Content */}
      <div className="col-md-6">
        <h1  style={{ fontSize: '48px', fontWeight: '700', color: "#ABBA7C" }}>About Us</h1>
        
        <p style={{ fontSize: '18px', lineHeight: '32px' }}>
          At Drift Tech Solution, we understand that a robust IT infrastructure is the backbone of any successful organization. Our mission is to deliver tailored solutions that enhance performance, security, and scalability, allowing our clients to focus on what they do best.
        </p>

        <p style={{ fontSize: '18px', lineHeight: '32px' }}>
          With expertise in IoT, cloud computing, network design, and cybersecurity, we leverage the latest technologies to ensure your systems are not only efficient but also resilient. Our collaborative approach means we work closely with you to understand your unique challenges and goals, crafting solutions that align with your vision.
        </p>

        <p style={{ fontSize: '18px', lineHeight: '32px' }}>
          What sets us apart is our extensive expertise and swift delivery. We take pride in contributing to our customers' success. Beyond our solutions, we also provide IT Managed Services, ensuring that your IT assets achieve maximum uptime for optimal customer satisfaction. Join us on a journey to transform your IT landscape and unlock the full potential of your business.
        </p>
      </div>

      {/* Right Column: Image */}
      <div className="col-md-6 position-relative">
        <img
          src="/images/pattern_design.svg"  // Replace with the path to your image
          alt="About Drift Tech Solution"
          className="img-fluid rounded pattern-img"
        />
      </div>
    </div>

    {/* Additional Image Below Content */}
    <div className="text-center mt-5">
      <img
        src="/images/about_us_img.svg"  // Replace with the actual path to your additional image
        alt="Additional Visual"
        className="img-fluid rounded"
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </div>
    <div className="row align-items-center">
      {/* Left Column: Content */}
      <div className="col-md-6 py-4">
        <h1  style={{ fontSize: '48px', fontWeight: '700',color: "#ABBA7C" }}>Our Mission</h1>
        
        <p style={{ fontSize: '18px', lineHeight: '32px' }}>
        Our mission is to create the most secure, organized, and hassle-free environment, empowering our customers to enhance their overall productivity and achieve greater success.</p>
      </div>

      {/* Right Column: Image */}
      <div className="col-md-6">
        <img
          src="/images/our_mission_img.svg"  // Replace with the path to your image
          alt="About Drift Tech Solution"
          className="img-fluid rounded py-4"
        />
      </div>
    </div>
    <div className="row align-items-center">
      {/* Left Column: Content */}
      <div className="col-md-6 py-4">
        <img
          src="/images/our_vision_img.svg"  // Replace with the path to your image
          alt="About Drift Tech Solution"
          className="img-fluid rounded py-4"
        />
      </div>
      <div className="col-md-6 ">
        <h1 style={{ fontSize: '48px', fontWeight: '700',color: "#ABBA7C" }}>Our Vision</h1>
        
        <p style={{ fontSize: '18px', lineHeight: '32px' }}>
        "Our vision is to be the most reliable and trusted brand, offering limitless services that empower and elevate our customers' experiences, setting new standards of excellence."        </p>
      </div>


    </div>
  </section>
);

export default AboutUs;
