import React from 'react';
import PropTypes from 'prop-types';
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const ServiceCard = ({ title, description, image, solutions = [], reverse = false }) => 
  { 

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return   (
      <div className="container scard my-5 border rounded shadow" style={{ backgroundColor: '#F3F7FD', padding: isMobile? '5px 1.7rem': '3rem' }}>
        <div className="row">
          {/* Conditionally reverse the order of columns */}
          <div className={`col-md-6 d-flex align-items-center justify-content-center ${reverse ? 'order-md-2' : ''}`} style={{paddingTop: isMobile? '0.5rem':''}}>
            <img
              src={image}
              alt={title}
              className="img-fluid rounded"
              style={{ maxWidth: isMobile ? '100%': '80%', height: 'auto'}}
            />
          </div>
          <div className="col-md-6 " style={{ paddingTop: isMobile ? 4: 0}} >
            <h2 style={{ color: '#ABBA7C', fontSize: isMobile ? '18px': '32px' }}>{title}</h2>
            <p style={{ textAlign: 'left'}}>{description}</p>
          </div>
        </div>
        {solutions.length > 0 && (
          <div className="row mt-4">
            <div className="col text-center" >
              <h4 className="font-weight-bold" style={{ fontSize: isMobile ? 14: 24}} >We offer solutions with the following products</h4>
              <div className="d-flex justify-content-center mt-3" style={{textAlign: 'left'}}>
                <ul className="list-unstyled row" style={{ marginLeft: isMobile? "": "2rem"}}>
                  {solutions.map((solution, idx) => (
                    <li key={idx} className="col-md-6 mb-2 d-flex align-items-baseline">
                      <div>
                      <img src="/images/points.svg" style={{ width: '1rem', height: '1rem', marginRight: 8}} alt="" />
                      </div>
                       {solution}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    );


  }


ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  solutions: PropTypes.arrayOf(PropTypes.string),
  reverse: PropTypes.bool,
};

export default ServiceCard;
