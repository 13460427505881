import React from 'react';
import "./partner.css";
import { styled, useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const logos = [
  { src: '/images/sequretek_logo.svg', alt: 'SecureTek' },
  { src: '/images/coredata_logo.svg', alt: 'CoreData' },
  // { src: '/images/netgear_logo.svg', alt: 'Netgear' },
  // { src: '/images/tplink_logo.svg', alt: 'TP-Link' },
  { src: '/images/heinrich-logo.png', alt: 'Heinrich' },
  // { src: '/images/zscaler-logo.png', alt: 'Zscaler' },
{src:'/images/siemons_logo.jpg', alt:'siemons logo'}
];

const PartnerSection = () => 
  {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  

    return   (
      <section className="text-center p-5">
        <h2 className="partner-title" style={{
                fontFamily: "Open Sans, sans-serif",
                fontSize: isMobile ? "32px": "64px",
                fontWeight: 700,
                textAlign: "center",
                color: "#ABBA7C",
              }}>Partnered OEM's</h2>
        <div className="logo-container">
          <div className="logo-wrapper">
            {logos.map((logo, index) => (
              <img key={index} src={logo.src} alt={logo.alt} className="partner-logo" />
            ))}
            {logos.map((logo, index) => (  // Duplicate the logos for infinite scrolling
              <img key={`dup-${index}`} src={logo.src} alt={logo.alt} className="partner-logo" />
            ))}
          </div>
        </div>
      </section>
    );
  }
  


export default PartnerSection;
